import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "now-that-we-have-a-react-app-using-the-ui-shell-its-time-to-build-a-few-static-pages-in-this-step-well-become-comfortable-with-the-carbon-grid-and-various-carbon-components"
    }}>{`Now that we have a React app using the UI Shell, it’s time to build a few static pages. In this step, we’ll become comfortable with the Carbon grid and various Carbon components.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add landing page grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build landing page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Style landing page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add repo page grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build repo page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Style repo page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "preview"
    }}>{`Preview`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://react-step-3--carbon-tutorial.netlify.com"
      }}>{`preview`}</a>{` of what you’ll build:`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe height="400" title="Carbon Tutorial Step 2" src="https://react-step-3--carbon-tutorial.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" />
  </Column>
    </Row>
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` that we’ll use as a starting point for each step. If you haven’t forked and cloned that repository yet, and haven’t added the upstream remote, go ahead and do so by following the `}<a parentName="p" {...{
        "href": "/tutorial/react-step-1#fork-clone--branch"
      }}>{`step 1 instructions`}</a>{`.`}</p>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`With your repository all set up, let’s check out the branch for this tutorial step’s starting point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b react-step-2 upstream/react-step-2
`}</code></pre>
    <p><em parentName="p">{`Note: This builds on top of step 1, but be sure to check out the upstream step 2 branch because it includes the static assets required to get through this step.`}</em></p>
    <h3 {...{
      "id": "build-and-start-app"
    }}>{`Build and start app`}</h3>
    <p>{`Install the app’s dependencies (in case you’re starting fresh in your current directory and not continuing from the previous step):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`Then, start the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn start
`}</code></pre>
    <p>{`You should see something similar to where the `}<a parentName="p" {...{
        "href": "/tutorial/react-step-1"
      }}>{`previous step`}</a>{` left off.`}</p>
    <h3 {...{
      "id": "ie11-polyfills"
    }}>{`IE11 polyfills`}</h3>
    <p>{`Before we get started with this step, we’ll be adding some components that require IE11 polyfills. As shown in the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/react/.storybook/polyfills.js"
      }}>{`Carbon React documentation`}</a>{`, go ahead and add these imports to the top of the root `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`. They aren’t all needed, but we’ll add them all to play it safe for the duration of the tutorial.`}</p>
    <h5 {...{
      "id": "srcindexjs"
    }}>{`src/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import 'core-js/modules/es7.array.includes';
import 'core-js/modules/es6.array.fill';
import 'core-js/modules/es6.string.includes';
import 'core-js/modules/es6.string.trim';
import 'core-js/modules/es7.object.values';
`}</code></pre>
    <p><em parentName="p">{`Note: You may be wondering where`}</em>{` `}<inlineCode parentName="p">{`core-js`}</inlineCode><em parentName="p">{`came from because it’s not a Carbon package and we haven’t explicitly installed it. Run`}</em>{` `}<inlineCode parentName="p">{`yarn why core-js`}</inlineCode>{` `}<em parentName="p">{`and you’ll see there are a number of packages that have brought it in as a dependency.`}</em></p>
    <h2 {...{
      "id": "install-grid"
    }}>{`Install grid`}</h2>
    <p>{`In our last step we added our styles, component and icon packages. Now that we’re building the pages with grid, we need to install one more Carbon package. Stop your development environment (`}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{`) and:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add @carbon/grid
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{`, we need to configure our grid to use 16 columns instead of the default 12 columns. We do this by adding `}<inlineCode parentName="p">{`grid-columns-16: true`}</inlineCode>{` in our `}<inlineCode parentName="p">{`$feature-flags`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srcindexscss"
    }}>{`src/index.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$feature-flags: (
  ui-shell: true,
  grid-columns-16: true
);
`}</code></pre>
    <p><em parentName="p">{`Note: Like before, the feature flag still needs to come before the Carbon`}</em>{` `}<inlineCode parentName="p">{`styles.scss`}</inlineCode>{` `}<em parentName="p">{`import.`}</em></p>
    <p>{`Run `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{` so we can begin building.`}</p>
    <h2 {...{
      "id": "add-landing-page-grid"
    }}>{`Add landing page grid`}</h2>
    <p>{`Let’s add our grid elements to `}<inlineCode parentName="p">{`LandingPage.js`}</inlineCode>{`.`}</p>
    <p>{`In order to use the grid, we need to wrap everything in a `}<inlineCode parentName="p">{`<div className="bx--grid">`}</inlineCode>{`. We can continue to make rows by adding a `}<inlineCode parentName="p">{`<div className="bx--row">`}</inlineCode>{` inside the grid, as well as make columns within those rows by adding `}<inlineCode parentName="p">{`<div className="bx--col-[breakpoint]-[size]">`}</inlineCode>{`.`}</p>
    <p>{`Our column sizes are specified by the number of columns they’ll be spanning. If we use `}<inlineCode parentName="p">{`bx--col-lg-4`}</inlineCode>{`, it means it’ll span 4 of the 16 columns. If we use `}<inlineCode parentName="p">{`bx--col-lg-8`}</inlineCode>{` it means it’ll span 8 of the 16 columns, and so on.`}</p>
    <p>{`We’ve included the designs for this tutorial app in the `}<inlineCode parentName="p">{`design.sketch`}</inlineCode>{` file found as a top-level file in the `}<inlineCode parentName="p">{`carbon-tutorial`}</inlineCode>{` repository. But, if you don’t have Sketch installed and available to inspect the design, we’ll provide screenshots.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "76.41921397379913%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Landing page grid",
        "title": "Landing page grid",
        "src": "/static/7f74bf609153609bb56b804b63244724/fb070/landing-layout.png",
        "srcSet": ["/static/7f74bf609153609bb56b804b63244724/d6747/landing-layout.png 288w", "/static/7f74bf609153609bb56b804b63244724/09548/landing-layout.png 576w", "/static/7f74bf609153609bb56b804b63244724/fb070/landing-layout.png 1152w", "/static/7f74bf609153609bb56b804b63244724/fb104/landing-layout.png 1728w", "/static/7f74bf609153609bb56b804b63244724/902fb/landing-layout.png 2304w", "/static/7f74bf609153609bb56b804b63244724/1d306/landing-layout.png 2748w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p><em parentName="p">{`Pro tip:`}</em>{` `}<inlineCode parentName="p">{`CTRL-L`}</inlineCode>{` `}<em parentName="p">{`toggles the layout in Sketch.`}</em></p>
    <p>{`We’ll break this down into three rows. The first row with the gray background doesn’t appear to need any columns. The second row with the white background looks like it has two columns of different widths. The third row with the gray background looks like it has four columns of equal width.`}</p>
    <p>{`We’ll make rows like so:`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <div className="bx--grid bx--grid--full-width landing-page">
    <div className="bx--row landing-page__banner">
      <div className="bx--col-lg-16">1</div>
    </div>
    <div className="bx--row landing-page__r2">
      <div className="bx--col-md-4 bx--col-lg-7">7/16</div>
      <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">8/16</div>
    </div>
    <div className="bx--row landing-page__r3">
      <div className="bx--col-md-4 bx--col-lg-4">1/4</div>
      <div className="bx--col-md-4 bx--col-lg-4">1/4</div>
      <div className="bx--col-md-4 bx--col-lg-4">1/4</div>
      <div className="bx--col-md-4 bx--col-lg-4">1/4</div>
    </div>
  </div>
);
`}</code></pre>
    <p>{`We added a class of `}<inlineCode parentName="p">{`bx--grid--full-width`}</inlineCode>{` to the grid container since our rows need to expand the whole page without any margins. We also added some custom classes like `}<inlineCode parentName="p">{`landing-page`}</inlineCode>{`, `}<inlineCode parentName="p">{`landing-page__banner`}</inlineCode>{`, `}<inlineCode parentName="p">{`landing-page__r2`}</inlineCode>{`, etc., which we will use later.`}</p>
    <p>{`Also, take notice of the second row. The tab content only covers 7 columns at this large viewport to prevent overly-large line lengths, so we needed to add a 1 column offset `}<inlineCode parentName="p">{`bx--offset-lg-1`}</inlineCode>{` to second column to fill the full 16 columns in the grid. Then, both of those columns have `}<inlineCode parentName="p">{`bx--col-md-4`}</inlineCode>{` classes so they are of equal width at medium-sized viewports.`}</p>
    <h2 {...{
      "id": "build-landing-page"
    }}>{`Build landing page`}</h2>
    <p>{`We’ll start adding HTML elements and components by row.`}</p>
    <h3 {...{
      "id": "first-row"
    }}>{`First row`}</h3>
    <p>{`In our first row we’ll need a `}<inlineCode parentName="p">{`BreadCrumb`}</inlineCode>{` component. First, let’s import the components we need. Because we’ll be importing several components for this page, we’ll import them directly from the `}<inlineCode parentName="p">{`carbon-components-react`}</inlineCode>{` package instead of the direct path for each one.`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-1"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
`}</code></pre>
    <p>{`We can now add our component to the first row, along with a header, like so:`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-2"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div className="bx--row landing-page__banner">
  <div className="bx--col-lg-16">
    <Breadcrumb noTrailingSlash>
      <BreadcrumbItem>
        <a href="/">Getting started</a>
      </BreadcrumbItem>
    </Breadcrumb>
    <h1 className="landing-page__heading">
      Design &amp; build with Carbon
    </h1>
  </div>
</div>
`}</code></pre>
    <p>{`You may notice that the styles look off. Don’t worry, we’ll fix these later.`}</p>
    <h3 {...{
      "id": "second-row"
    }}>{`Second row`}</h3>
    <p>{`In our second row we’ll need `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` and `}<inlineCode parentName="p">{`Button`}</inlineCode>{` components. We’ll update the `}<inlineCode parentName="p">{`carbon-components-react`}</inlineCode>{` import to:`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-3"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
} from 'carbon-components-react';
`}</code></pre>
    <p>{`Before we can render the tabs, we need to add some props for the component after the import. These example props came from the `}<a parentName="p" {...{
        "href": "http://react.carbondesignsystem.com/?selectedKind=Tabs"
      }}>{`React Tabs Story`}</a>{` in Storybook.`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-4"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};
`}</code></pre>
    <p>{`Modify the second row to use the `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` component.`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-5"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div className="bx--row landing-page__r2">
  <div className="bx--col bx--no-gutter">
    <Tabs {...props.tabs}>
      <Tab {...props.tab} label="About">
        <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div className="bx--row landing-page__tab-content">
            <div className="bx--col-md-4 bx--col-lg-7">7/16</div>
            <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
              8/16
            </div>
          </div>
        </div>
      </Tab>
      <Tab {...props.tab} label="Design">
        <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div className="bx--row landing-page__tab-content">
            <div className="bx--col-lg-16">
              Rapidly build beautiful and accessible experiences. The Carbon kit
              contains all resources you need to get started.
            </div>
          </div>
        </div>
      </Tab>
      <Tab {...props.tab} label="Develop">
        <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div className="bx--row landing-page__tab-content">
            <div className="bx--col-lg-16">
              Carbon provides styles and components in Vanilla, React, Angular,
              and Vue for anyone building on the web.
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</div>
`}</code></pre>
    <p><em parentName="p">{`Note: We’re using the grid for the page layout, but we also need to apply the grid within the tab content. When doing so, make sure the nested grid has the expected`}</em>{` `}<inlineCode parentName="p">{`grid`}</inlineCode>{` `}<em parentName="p">{`>`}</em>{` `}<inlineCode parentName="p">{`row`}</inlineCode>{` `}<em parentName="p">{`>`}</em>{` `}<inlineCode parentName="p">{`col`}</inlineCode>{` `}<em parentName="p">{`DOM structure.`}</em></p>
    <p>{`Hold up! If you were to run `}<a parentName="p" {...{
        "href": "https://www.ibm.com/able/dynamic-assessment-plug-in.html"
      }}>{`DAP`}</a>{` to check for accessibility violations, you’d see `}<inlineCode parentName="p">{`Multiple navigation landmarks must have unique labels specified with aria-label or aria-labelledby`}</inlineCode>{` because both the `}<inlineCode parentName="p">{`Breadcrumb`}</inlineCode>{` and `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` components use `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{` elements. To fix, add `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to the `}<inlineCode parentName="p">{`Breadcrumb`}</inlineCode>{` opening tag:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<Breadcrumb noTrailingSlash aria-label="Page navigation">
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Same goes for the `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` opening tag:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<Tabs {...props.tabs} aria-label="Tab navigation">
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Next, we’ll need to add a styling override to move the tabs to the right on large viewports. Create a file `}<inlineCode parentName="p">{`_overrides.scss`}</inlineCode>{` in `}<inlineCode parentName="p">{`src/content/LandingPage`}</inlineCode>{` with this declaration block.`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_overridesscss"
    }}>{`src/content/LandingPage/`}{`_`}{`overrides.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__r2 .bx--tabs__nav {
  right: 0;
}
`}</code></pre>
    <p>{`Then in `}<inlineCode parentName="p">{`_landing-page.scss`}</inlineCode>{` add this import at the top of the file.`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import './overrides.scss';
`}</code></pre>
    <p><em parentName="p">{`Note: We don’t have to include this in a separate file, but it’s nice to keep overrides separate from your application’s styling so when migrating to future Carbon versions and if there are breaking changes via different class names, you have a consolidated list of styling declaration blocks to review.`}</em></p>
    <p>{`We can now add our images and text for each column in the first `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` in `}<inlineCode parentName="p">{`LandingPage.js`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-6"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<Tab {...props.tab} label="About">
  <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
    <div className="bx--row landing-page__tab-content">
      <div className="bx--col-md-4 bx--col-lg-7">
        <h2 className="landing-page__subheading">
          What is Carbon?
        </h2>
        <p className="landing-page__p">
          Carbon is IBM’s open-source design system for digital
          products and experiences. With the IBM Design Language
          as its foundation, the system consists of working code,
          design tools and resources, human interface guidelines,
          and a vibrant community of contributors.
        </p>
        <Button>Learn more</Button>
      </div>
      <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
        <img
          className="landing-page__illo"
          src={\`\${process.env.PUBLIC_URL}/tab-illo.png\`}
          alt="Carbon illustration"
        />
      </div>
    </div>
  </div>
</Tab>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Now let’s set the image size in `}<inlineCode parentName="p">{`_landing-page.scss`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-1"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__illo {
  max-width: 100%;
}
`}</code></pre>
    <p>{`Assuming that the second and third tab would have a similar design, we would set them up in the same way. However, since our design specs don’t show those tabs, we’ll leave the code as is.`}</p>
    <h3 {...{
      "id": "third-row"
    }}>{`Third row`}</h3>
    <p>{`The third row will be created in a later tutorial, so we’ll just add the headers for now.`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs-7"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div className="bx--row landing-page__r3">
  <div className="bx--col-md-4 bx--col-lg-4">
    <h3 className="landing-page__label">The Principles</h3>
  </div>
  <div className="bx--col-md-4 bx--col-lg-4">Carbon is Open</div>
  <div className="bx--col-md-4 bx--col-lg-4">Carbon is Modular</div>
  <div className="bx--col-md-4 bx--col-lg-4">Carbon is Consistent</div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "style-landing-page"
    }}>{`Style landing page`}</h2>
    <p>{`We’ve added basic layout styles in `}<inlineCode parentName="p">{`_landing-page.scss`}</inlineCode>{`, so now let’s add type, color and spacing styles to match the design. We’ll be using our `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/spacing"
      }}>{`spacing tokens`}</a>{`. In `}<inlineCode parentName="p">{`app.scss`}</inlineCode>{`, add these imports at the `}<strong parentName="p">{`top`}</strong>{` of the file so we can use Carbon breakpoints, tokens, and typography Sass mixins and functions:`}</p>
    <h5 {...{
      "id": "srcappscss"
    }}>{`src/app.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';
`}</code></pre>
    <h3 {...{
      "id": "banner"
    }}>{`Banner`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "42.79411764705882%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Banner vertical spacing",
        "title": "Banner vertical spacing",
        "src": "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/fb070/landing-r1-spacing.png",
        "srcSet": ["/static/13033ffbcd2fa2128eac6e3c8bef7ea7/d6747/landing-r1-spacing.png 288w", "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/09548/landing-r1-spacing.png 576w", "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/fb070/landing-r1-spacing.png 1152w", "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/55534/landing-r1-spacing.png 1360w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p><em parentName="p">{`Pro tip:`}</em>{` `}<inlineCode parentName="p">{`CTRL-G`}</inlineCode>{` `}<em parentName="p">{`toggles the grid in Sketch.`}</em></p>
    <p>{`Back to `}<inlineCode parentName="p">{`_landing-page.scss`}</inlineCode>{`, we need to add space above the breadcrumb and below the heading. For that, add:`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-2"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
}
`}</code></pre>
    <p>{`Referencing the `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/spacing#spacing-scale"
      }}>{`spacing token table`}</a>{`, `}<inlineCode parentName="p">{`16px`}</inlineCode>{` can be set with the `}<inlineCode parentName="p">{`$spacing-05`}</inlineCode>{` token. The design calls for `}<inlineCode parentName="p">{`128px`}</inlineCode>{` of space below the heading and that’s not in the spacing scale, we can achieve that in Sass by multiplying 32px (`}<inlineCode parentName="p">{`$spacing-07`}</inlineCode>{`) by 4. We could use `}<inlineCode parentName="p">{`128px`}</inlineCode>{` or `}<inlineCode parentName="p">{`8rem`}</inlineCode>{` directly in our styling, but using our tokens preserves consistency should the token values get updated in the future.`}</p>
    <p>{`Looking at the design, we need a wall-to-wall light gray background behind the banner and also behind the third row. This is a great opportunity to use a Sass mixin. We could put this at the top of `}<inlineCode parentName="p">{`_landing-page.scss`}</inlineCode>{`, but it’s best practice to place mixins in a dedicated file, so create a `}<inlineCode parentName="p">{`_mixins.scss`}</inlineCode>{` file in `}<inlineCode parentName="p">{`src/content/LandingPage`}</inlineCode>{`.`}</p>
    <p>{`Add the following in `}<inlineCode parentName="p">{`_mixins.scss`}</inlineCode>{`. Per the design we need to use Gray 10 for our banner background color, which can be set with the `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/color/usage"
      }}>{`color token`}</a>{`. Also, we want the background to extend into the grid’s outermost gutters to go the full width of the viewport, so given the DOM structure, we can achieve that by setting the background in an absolutely positioned pseudo element.`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_mixinsscss"
    }}>{`src/content/LandingPage/`}{`_`}{`mixins.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@mixin landing-page-background() {
  background-color: $ui-01;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -$spacing-05;
    top: 0;
    right: -$spacing-05;
    bottom: 0;
    background: $ui-01;
    z-index: -1;
  }
}
`}</code></pre>
    <p>{`After you have created `}<inlineCode parentName="p">{`_mixins.scss`}</inlineCode>{`, import it at the `}<strong parentName="p">{`top`}</strong>{` of `}<inlineCode parentName="p">{`_landing-page.scss`}</inlineCode>{`. By now you should have two imports:`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-3"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import './mixins.scss';
@import './overrides.scss';
`}</code></pre>
    <p>{`Now to use the new mixin, update the `}<inlineCode parentName="p">{`.landing-page__banner`}</inlineCode>{` declaration block to:`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-4"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  @include landing-page-background;
}
`}</code></pre>
    <p>{`Next, we can see that the `}<inlineCode parentName="p">{`h1`}</inlineCode>{` is using the `}<inlineCode parentName="p">{`heading-05`}</inlineCode>{` type token.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "61.029411764705884%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Banner heading type",
        "title": "Banner heading type",
        "src": "/static/c7190e1685d5378baf9db14edf1269de/fb070/landing-r1-type.png",
        "srcSet": ["/static/c7190e1685d5378baf9db14edf1269de/d6747/landing-r1-type.png 288w", "/static/c7190e1685d5378baf9db14edf1269de/09548/landing-r1-type.png 576w", "/static/c7190e1685d5378baf9db14edf1269de/fb070/landing-r1-type.png 1152w", "/static/c7190e1685d5378baf9db14edf1269de/55534/landing-r1-type.png 1360w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`The Sketch symbol naming is consistent with the development Sass tokens to help translate design to development. So, looking up the `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/typography/productive"
      }}>{`type token`}</a>{`, we know to use `}<inlineCode parentName="p">{`productive-heading-05`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-5"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__heading {
  @include carbon--type-style('productive-heading-05');
}
`}</code></pre>
    <h3 {...{
      "id": "row-two"
    }}>{`Row two`}</h3>
    <p>{`For our second row, we need to fix the tabs vertical positioning to match the design. By inspecting the tabs component, you can see that the tab height computes to `}<inlineCode parentName="p">{`40px`}</inlineCode>{`. We can use that to create our negative top margin in rem units.`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-6"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__r2 {
  margin-top: rem(-40px);
}
`}</code></pre>
    <p>{`We also need to adjust our vertical spacing and type treatment. Like before, it’s a matter of using spacing and type tokens like so:`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "58.970588235294116%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Row 2 vertical spacing",
        "title": "Row 2 vertical spacing",
        "src": "/static/33ed6696287eb1b5eb002b64b02773f7/fb070/landing-r2-spacing.png",
        "srcSet": ["/static/33ed6696287eb1b5eb002b64b02773f7/d6747/landing-r2-spacing.png 288w", "/static/33ed6696287eb1b5eb002b64b02773f7/09548/landing-r2-spacing.png 576w", "/static/33ed6696287eb1b5eb002b64b02773f7/fb070/landing-r2-spacing.png 1152w", "/static/33ed6696287eb1b5eb002b64b02773f7/55534/landing-r2-spacing.png 1360w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p><em parentName="p">{`Note: You may be wondering why there are vertical gaps between the type and spacers. Each type token has a line height that’s suited for its font size. The vertical spacers adjacently touch the line height boundaries and not the baseline, for consistency as well as development ease so`}</em>{` `}<inlineCode parentName="p">{`margins`}</inlineCode>{` `}<em parentName="p">{`and`}</em>{` `}<inlineCode parentName="p">{`paddings`}</inlineCode>{` `}<em parentName="p">{`don’t need to offset line heights.`}</em></p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-7"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__tab-content {
  padding-top: $layout-05;
  padding-bottom: $layout-05;
}

.landing-page__subheading {
  @include carbon--type-style('productive-heading-03');
  @include carbon--font-weight('semibold');
}

.landing-page__p {
  @include carbon--type-style('productive-heading-03');
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;

  @include carbon--breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}
`}</code></pre>
    <h3 {...{
      "id": "row-three"
    }}>{`Row three`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "24.558823529411764%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Row 3 vertical spacing",
        "title": "Row 3 vertical spacing",
        "src": "/static/014fc9b6ccd2fa4a8691bb8abd966c94/fb070/landing-r3-spacing.png",
        "srcSet": ["/static/014fc9b6ccd2fa4a8691bb8abd966c94/d6747/landing-r3-spacing.png 288w", "/static/014fc9b6ccd2fa4a8691bb8abd966c94/09548/landing-r3-spacing.png 576w", "/static/014fc9b6ccd2fa4a8691bb8abd966c94/fb070/landing-r3-spacing.png 1152w", "/static/014fc9b6ccd2fa4a8691bb8abd966c94/55534/landing-r3-spacing.png 1360w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`Let’s also add some styles for the last row, even though that will get used later in the tutorial. You’ll notice that we get to re-use the `}<inlineCode parentName="p">{`landing-page-background`}</inlineCode>{` mixin that we just created.`}</p>
    <h5 {...{
      "id": "srccontentlandingpage_landing-pagescss-8"
    }}>{`src/content/LandingPage/`}{`_`}{`landing-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  @include landing-page-background;
}

.landing-page__label {
  @include carbon--type-style('heading-01');
}
`}</code></pre>
    <p>{`Ta-da! You should see a finished landing page! Now we can move on to the repo page.`}</p>
    <h2 {...{
      "id": "add-repo-page-grid"
    }}>{`Add repo page grid`}</h2>
    <p>{`Now in our `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{` we’ll add our grid containers in the `}<inlineCode parentName="p">{`return`}</inlineCode>{` section.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <div className="bx--grid bx--grid--full-width bx--grid--no-gutter repo-page">
    <div className="bx--row repo-page__r1">
      <div className="bx--col-lg-16">Data table will go here</div>
    </div>
  </div>
);
`}</code></pre>
    <h2 {...{
      "id": "build-repo-page"
    }}>{`Build repo page`}</h2>
    <p>{`We currently have `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{` that just contains a grid and placeholder content for the time being. In the next tutorial step we’re going to be querying an API to populate the `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` component in this page. As a best practice to separate data fetching from the presentation components, go ahead and create a `}<inlineCode parentName="p">{`RepoTable.js`}</inlineCode>{` as a sibling to `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{` in `}<inlineCode parentName="p">{`src/content/RepoPage`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "build-data-table"
    }}>{`Build data table`}</h3>
    <p>{`First, we’ll add our data table by importing a few components in `}<inlineCode parentName="p">{`RepoTable.js`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepotablejs"
    }}>{`src/content/RepoPage/RepoTable.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableExpandHeader,
  TableHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
} from 'carbon-components-react';
`}</code></pre>
    <p>{`Then, let’s create the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component and export it at the very bottom of the page.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepotablejs-1"
    }}>{`src/content/RepoPage/RepoTable.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const RepoTable = ({ rows, headers }) => {
  return (
    <DataTable
      rows={rows}
      headers={headers}
      render={({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
      }) => (
        <TableContainer
          title="Carbon Repositories"
          description="A collection of public Carbon repositories.">
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                <TableExpandHeader />
                {headers.map(header => (
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <React.Fragment key={row.id}>
                  <TableExpandRow {...getRowProps({ row })}>
                    {row.cells.map(cell => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableExpandRow>
                  <TableExpandedRow colSpan={headers.length + 1}>
                    <p>Row description</p>
                  </TableExpandedRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    />
  );
};

export default RepoTable;
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`This component uses two props, `}<inlineCode parentName="p">{`rows`}</inlineCode>{` and `}<inlineCode parentName="p">{`headers`}</inlineCode>{`, and returns a Carbon `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{`. As for where the various `}<inlineCode parentName="p">{`Table*`}</inlineCode>{` components came from? The `}<a parentName="p" {...{
        "href": "http://react.carbondesignsystem.com/?selectedKind=DataTable&selectedStory=with%20expansion"
      }}>{`DataTable story`}</a>{` in Storybook was used to put together the data table structure.`}</p>
    <p><em parentName="p">{`Note: The`}</em>{` `}<inlineCode parentName="p">{`getTableProps`}</inlineCode><em parentName="p">{`,`}</em>{` `}<inlineCode parentName="p">{`getHeaderProps`}</inlineCode><em parentName="p">{`, and`}</em>{` `}<inlineCode parentName="p">{`getRowProps`}</inlineCode>{` `}<em parentName="p">{`functions each tell the`}</em>{` `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` `}<em parentName="p">{`component to process and return the necessary props that are needed by the`}</em>{` `}<inlineCode parentName="p">{`Table`}</inlineCode><em parentName="p">{`,`}</em>{` `}<inlineCode parentName="p">{`Header`}</inlineCode>{` `}<em parentName="p">{`and`}</em>{` `}<inlineCode parentName="p">{`Row`}</inlineCode>{` `}<em parentName="p">{`components respectively. We take the return of that function (an object containing the props) and `}<a parentName="em" {...{
          "href": "https://reactjs.org/docs/jsx-in-depth.html#spread-attributes"
        }}>{`spread it out`}</a>{` into the component.`}</em></p>
    <p>{`At this point, return to `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{` because now we need to render a static `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "render-data-table"
    }}>{`Render data table`}</h3>
    <p>{`Import `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` in `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-1"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import RepoTable from './RepoTable';
`}</code></pre>
    <p>{`Then below the imports, include the following arrays to pass into the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component. We’ll be setting the `}<inlineCode parentName="p">{`rows`}</inlineCode>{` array from an API in the next tutorial step, but for now, static example rows will suffice.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-2"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'createdAt',
    header: 'Created',
  },
  {
    key: 'updatedAt',
    header: 'Updated',
  },
  {
    key: 'issueCount',
    header: 'Open Issues',
  },
  {
    key: 'stars',
    header: 'Stars',
  },
  {
    key: 'links',
    header: 'Links',
  },
];

const rows = [
  {
    id: '1',
    name: 'Repo 1',
    createdAt: 'Date',
    updatedAt: 'Date',
    issueCount: '123',
    stars: '456',
    links: 'Links',
  },
  {
    id: '2',
    name: 'Repo 2',
    createdAt: 'Date',
    updatedAt: 'Date',
    issueCount: '123',
    stars: '456',
    links: 'Links',
  },
  {
    id: '3',
    name: 'Repo 3',
    createdAt: 'Date',
    updatedAt: 'Date',
    issueCount: '123',
    stars: '456',
    links: 'Links',
  },
];
`}</code></pre>
    <p>{`Lastly in `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{`, we need to simply replace `}<inlineCode parentName="p">{`Data table will go here`}</inlineCode>{` with:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-3"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<RepoTable headers={headers} rows={rows} />
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <h2 {...{
      "id": "style-repo-page"
    }}>{`Style repo page`}</h2>
    <p>{`Our styles for the repo page are mostly fine. We just need to update a few vertical spacing issues.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`_repo-page.scss`}</inlineCode>{`, add the following styles:`}</p>
    <h5 {...{
      "id": "srccontentrepopage_repo-pagescss"
    }}>{`src/content/RepoPage/`}{`_`}{`repo-page.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.repo-page .bx--row {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
}
`}</code></pre>
    <p>{`Congratulations! We’ve now created our static repo page!`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`Run the CI check to make sure we’re all set to submit a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues running the CI check? `}<a parentName="em" {...{
          "href": "/tutorial/react-step-1#continuous-integration-(ci)-check"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 2"
`}</code></pre>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin react-step-2
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues pushing your changes? `}<a parentName="em" {...{
          "href": "/tutorial/react-step-1#git-commit-and-push"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`react-step-2`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: react-step-2`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      